import React from "react";

interface PaddingProps {
  top?: number; // Padding for the top
  bottom?: number; // Padding for the bottom
  children: React.ReactNode; // Children to render inside the component
}

const Padding: React.FC<PaddingProps> = ({ top = 0, bottom = 0, children }) => {
  const style = {
    paddingTop: `${top}px`,
    paddingBottom: `${bottom}px`,
  };

  return <div style={style}>{children}</div>;
};

export default Padding;
